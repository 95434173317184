import React from "react";
import { styled } from "@mui/material";
import ProductsFeed from "./ProductsFeed";

const MainContainer = styled("div")(() => ({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
  columnGap: "5%",
  margin: "50px",
}));

const Packages: React.FC = () => {
  return (
    <MainContainer>
      <ProductsFeed limit={10} />
    </MainContainer>
  );
};

export default Packages;
