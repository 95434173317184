import React from "react";
import { SilverHead, TextCenter } from "./styledComponents";
import { useQuery } from "@apollo/client";
import { getProfileFeedQuery } from "../graphql";
import { Waypoint } from "react-waypoint";
import CheckoutDialog from "./CheckoutDialog";
import { CircularProgress, Typography, styled, Button } from "@mui/material";
import { Post } from "../interfaces/post";

const BuyContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
}));

interface ContainerProps {
  noLoad?: Boolean;
  limit: Number;
}

const ProductsFeed: React.FC<ContainerProps> = ({
  noLoad = false,
  limit = 10,
}) => {
  let videoPlayer = React.useRef<HTMLVideoElement>(null);
  const { loading, data, fetchMore } = useQuery(getProfileFeedQuery, {
    variables: { username: "mmoments" },
  });
  const [openCheckout, setOpenCheckout] = React.useState(false);
  const [checkoutProduct, setCheckoutProduct] = React.useState<Post | null>(
    null
  );

  const initVideo = () => {
    const videoEle = videoPlayer.current;

    if (videoEle) videoEle.muted = true;
  };

  return loading ? (
    <CircularProgress />
  ) : data ? (
    <>
      {checkoutProduct ? (
        <CheckoutDialog
          open={openCheckout}
          product={checkoutProduct}
          close={() => {
            setOpenCheckout(false);
          }}
        />
      ) : null}

      {data.getProfileFeed
        .filter((p: Post) => p.selling)
        .map((p: Post) => (
          <div key={p.id}>
            {console.log(p)}
            {p.postMedia[0]
            ? {
                "image/jpeg": (
                  <img src={p.postMedia[0].fileName} alt="product display" />
                ),
                "image/png": (
                  <img src={p.postMedia[0].fileName} alt="product display" />
                ),
                "video/quicktime": (
                  <video
                    ref={videoPlayer}
                    onCanPlay={initVideo}
                    src={p.postMedia[0].fileName}
                    muted
                    autoPlay
                    loop
                    playsInline
                  ></video>
                ),
              }[p.postMedia[0].mediaType]
            : null}
            <SilverHead variant="h4">{p.heading}</SilverHead>
            <Typography>{p.body}</Typography>
            <BuyContainer>
              <TextCenter>${p.price}</TextCenter>
              <Button
                style={{ alignSelf: "center", margin: "20px" }}
                variant="contained"
                onClick={() => {
                  setCheckoutProduct(p);
                  setOpenCheckout(true);
                }}
              >
                Buy
              </Button>
            </BuyContainer>
          </div>
        ))}
      <Waypoint
        onEnter={() => {
          fetchMore({
            variables: {
              username: "mmoments",
              limit,
              offset: data.getProfileFeed.length,
            },
          });
        }}
      />
    </>
  ) : (
    <Typography>No packages</Typography>
  );
};

export default ProductsFeed;
