import React from "react";
import { styled } from "@mui/material";
import MomentsFeed from "./MomentsFeed";

const PrevMomentsVids = styled("div")(() => ({
  margin: "10px",
  display: "grid",
  rowGap: "25px",
  columnGap: "25px",
  justifyContent: "center",
  gridTemplateColumns: "repeat(auto-fit, 370px)",
}));

const Moments: React.FC = () => {
  return (
    <PrevMomentsVids>
      <MomentsFeed />
    </PrevMomentsVids>
  );
};

export default Moments;
