import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { grey, orange } from "@mui/material/colors";
import { BrowserRouter } from "react-router-dom";
import { AppStateProvider } from "./components/AppStateContext";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBKEY!);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message }) => {
      console.log(`Graphql error ${message}`);
    });
  }
});

const link = from([
  errorLink,
  new HttpLink({ uri: process.env.REACT_APP_API_URL }),
]);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: link,
});

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: grey,
    secondary: orange,
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: "standard",
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <AppStateProvider>
            <Elements stripe={stripePromise}>
              <App />
            </Elements>
          </AppStateProvider>
        </BrowserRouter>
      </ThemeProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
