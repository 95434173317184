import React from "react";
import { Route, Switch } from "react-router-dom";
import Nav from "./components/Nav";
import Main from "./components/Main";
import Packages from "./components/Packages";
import Moments from "./components/Moments";

import {
  Paper,
  styled
} from "@mui/material";

const navHeight = 68;

const AppMain = styled("div")(() => ({
  display: "grid",
  height: "100vh",
  gridTemplateRows: "repeat(2, auto)",
  overflow: "hidden",
}));

const ContentMain = styled("div")(() => ({
  height: "100vh",
  overflowY: "scroll",
}));

const NavContainer = styled("div")(() => ({
  height: `${navHeight}px`,
}));

const App: React.FC = () => {
  return (
    <Paper style={{ width: "100vw", height: "100vh" }}>
      <AppMain>
        <NavContainer>
          <Nav />
        </NavContainer>
        <ContentMain>
          <Switch>
            <Route exact path="/">
              <Main />
            </Route>
            <Route exact path="/packages">
              <Packages />
            </Route>
            <Route exact path="/moments">
              <Moments />
            </Route>
          </Switch>
        </ContentMain>
      </AppMain>
    </Paper>
  );
};

export default App;
