import { Typography, styled, CardHeader } from "@mui/material";

export const YellowCardHead = styled(CardHeader)(() => ({
  padding: "5px 8px",
  color: "#c88f0f",
  textAlign: "center",
}));

export const YellowHead = styled(Typography)(() => ({
  padding: "5px 8px",
  color: "#c88f0f",
}));

export const SilverHead = styled(Typography)(() => ({
  padding: "5px 0px",
  color: "#a0a0a0",
}));

export const TopSpacing = styled(Typography)(() => ({
  paddingTop: "20px",
}));

export const TextCenter = styled(Typography)(() => ({
  textAlign: "center",
}));
