import React from "react";
import { Link } from "react-router-dom";
import { LoSearch } from "@lolumi/common";
import { getSearchResultsQuery } from "../graphql";
import { useLazyQuery } from "@apollo/client";
import { AppBar, Toolbar, Button, styled } from "@mui/material";

const NavButton = styled(Button)(() => ({
  width: 200,
}));

const Nav: React.FC = () => {
  return (
    <>
      <AppBar color="primary">
        <Toolbar>
          <Link to="/">
            <img
              src="assets/icon/icon.png"
              alt="Moments logo"
              style={{ width: "60px" }}
            />
          </Link>
          <NavButton href="/packages">
            Packages
          </NavButton>
          <NavButton href="/moments">
            Past Moments
          </NavButton>
          <LoSearch
            searchAction={useLazyQuery(getSearchResultsQuery, {
              fetchPolicy: "network-only",
            })}
          />
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  );
};

export default Nav;
