import React from "react";
import { YellowCardHead } from "./styledComponents";
import { useQuery } from "@apollo/client";
import { getProfileFeedQuery } from "../graphql";
import { Waypoint } from "react-waypoint";

import {
  Card,
  CardContent,
  CircularProgress,
  Typography,
  styled,
} from "@mui/material";
import { Post } from "../interfaces/post";

const FullVideo = styled("video")(() => ({
  width: "100%",
  height: "100%",
}));

const MomentsFeed: React.FC = () => {
  const { loading, data, fetchMore } = useQuery(getProfileFeedQuery, {
    variables: { username: "mmoments", limit: 10, offset: 0 },
  });

  return loading ? (
    <CircularProgress />
  ) : data ? (
    <>
      {data.getProfileFeed
        .filter((m: Post) => !m.selling)
        .map((m: Post) => (
          <Card key={m.id}>
            <YellowCardHead title={m.heading} />
            <CardContent>
              <FullVideo controls={true}>
                <source type="video/mp4" src={m.postMedia[0].fileName} />
              </FullVideo>
            </CardContent>
          </Card>
        ))}
      <Waypoint
        onEnter={() => {
          fetchMore({
            variables: {
              limit: 10,
              offset: data.getProfileFeed.length,
            },
          });
        }}
      />
    </>
  ) : (
    <Typography>No moments</Typography>
  );
};

export default MomentsFeed;
