import React from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useMutation } from "@apollo/client";
import { createOrderMutation } from "../graphql";
import { format } from "date-fns";
import { LocalizationProvider, MobileDatePicker } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterDateFns";

import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { Post } from "../interfaces/post";

const CheckoutContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
}));

const defaultFormState = {
  name: "",
  email: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  eventDate: "",
  eventLocation: "",
  openCasket: true,
};

interface ContainerProps {
  product: Post;
  open: boolean;
  close: () => void;
}

const CheckoutDialog: React.FC<ContainerProps> = ({ product, open, close }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [cardError, setCardError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [orderSuccess, setOrderSuccess] = React.useState({
    successful: false,
    amount: 0,
    createdAt: 0,
  });

  const [createOrder] = useMutation(createOrderMutation, {
    update(_, result) {
      const initialOrder = (result as any).data.createOrder;
      const handleOrder = async (initialOrder: any) => {
        const cardElement = elements?.getElement(CardElement);
        if (cardElement) {
          const paymentMethodReq = await stripe?.createPaymentMethod({
            type: "card",
            card: cardElement,
            billing_details: {
              name: formValues.name,
              email: formValues.email,
              address: {
                line1: formValues.address,
                city: formValues.city,
                state: formValues.state,
                postal_code: formValues.zip,
              },
            },
          });

          if (paymentMethodReq) {
            const confirmedCardPayment = await stripe?.confirmCardPayment(
              initialOrder.intentSecret,
              {
                receipt_email: formValues.email,
                payment_method: paymentMethodReq.paymentMethod?.id,
              }
            );

            if (confirmedCardPayment) {
              if (confirmedCardPayment.error)
                setCardError(
                  (confirmedCardPayment?.error &&
                    confirmedCardPayment?.error.message) as string
                );
              else {
                setOrderSuccess({
                  successful: true,
                  amount: confirmedCardPayment.paymentIntent.amount,
                  createdAt: confirmedCardPayment.paymentIntent.created,
                });
              }
            }
          }
        }
      };

      handleOrder(initialOrder);
      setLoading(false);
    },
  });

  const [formValues, setFormValues] = React.useState(defaultFormState);
  const [validationErrors, setValidationErrors] = React.useState({
    name: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    eventDate: "",
    eventLocation: "",
    openCasket: true,
  });

  React.useEffect(() => {
    if (open) {
      setFormValues(defaultFormState);
      setOrderSuccess({
        successful: false,
        amount: 0,
        createdAt: 0,
      });
    }
  }, [open]);

  const handleDateChange = (n: Date | null) => {
    if (n) {
      setFormValues({
        ...formValues,
        eventDate: n.toDateString(),
      });
    }
  };

  const handleInputChange = (e: any) => {
    let { name, value, checked } = e.target;
    setCardError("");

    if (name === "openCasket") value = checked;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleCheckout = () => {
    setLoading(true);
    createOrder({
      variables: {
        postID: product.id,
        extraInfo: [
          { name: "email", value: formValues.email },
          { name: "openCasket", value: formValues.openCasket },
          { name: "eventDate", value: formValues.eventDate },
          { name: "eventLocation", value: formValues.eventLocation },
        ],
      },
    });
  };

  return (
    <Dialog open={open} onClose={close}>
      {orderSuccess.successful ? (
        <>
          <DialogTitle>Your Order was successful!</DialogTitle>
          <DialogContent
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h2">{product.heading}</Typography>
            <Typography>Amount Paid: ${orderSuccess.amount / 100}</Typography>
            <Typography>
              Date Paid:
              {format(new Date(orderSuccess.createdAt * 1000), " MMMM d, yyyy")}
            </Typography>
            <Typography>Your receipt has been emailed</Typography>
          </DialogContent>
        </>
      ) : (
        <>
          <DialogTitle>
            Checkout {product.heading} ${product.price}
          </DialogTitle>
          <DialogContent>
            <CheckoutContainer>
              <TextField
                error={validationErrors.email ? true : false}
                fullWidth
                name="name"
                label="Name"
                helperText={validationErrors.name}
                value={formValues.name}
                onChange={handleInputChange}
              />
              <TextField
                error={validationErrors.email ? true : false}
                fullWidth
                name="email"
                label="Email"
                helperText={validationErrors.email}
                value={formValues.email}
                onChange={handleInputChange}
              />
              <TextField
                error={validationErrors.address ? true : false}
                fullWidth
                name="address"
                label="Address"
                helperText={validationErrors.address}
                value={formValues.address}
                onChange={handleInputChange}
              />
              <TextField
                error={validationErrors.city ? true : false}
                fullWidth
                name="city"
                label="City"
                helperText={validationErrors.city}
                value={formValues.city}
                onChange={handleInputChange}
              />
              <TextField
                error={validationErrors.state ? true : false}
                fullWidth
                name="state"
                label="State"
                helperText={validationErrors.state}
                value={formValues.state}
                onChange={handleInputChange}
              />
              <TextField
                error={validationErrors.zip ? true : false}
                fullWidth
                name="zip"
                label="ZIP"
                helperText={validationErrors.zip}
                value={formValues.zip}
                onChange={handleInputChange}
              />
              <Divider />
              <TextField
                error={validationErrors.eventLocation ? true : false}
                fullWidth
                name="eventLocation"
                label="Event Location"
                helperText={validationErrors.eventLocation}
                value={formValues.eventLocation}
                onChange={handleInputChange}
              />
              <FormControlLabel
                label="Open Casket?"
                control={
                  <Checkbox
                    name="openCasket"
                    checked={formValues.openCasket}
                    onChange={handleInputChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
              />
              <LocalizationProvider dateAdapter={DateAdapter}>
                <MobileDatePicker
                  label="Event Date"
                  inputFormat="MM/dd/yyyy"
                  value={formValues.eventDate}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <CardElement />
              {loading ? (
                <CircularProgress />
              ) : (
                <Typography color="error">{cardError}</Typography>
              )}
              <Button
                disabled={loading}
                variant="contained"
                color="primary"
                onClick={handleCheckout}
              >
                Checkout
              </Button>
            </CheckoutContainer>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default CheckoutDialog;
