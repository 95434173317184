import { gql } from "@apollo/client";

export const loginQuery = gql`
  query login(
    $username: String
    $email: String
    $phonenum: String
    $password: String!
  ) {
    auth(
      username: $username
      email: $email
      phonenum: $phonenum
      password: $password
    ) {
      id
      username
      email
      gender
      profileimg
      token
    }
  }
`;

export const registerMutation = gql`
  mutation register($registerInput: RegisterInput!) {
    register(registerInput: $registerInput) {
      id
      email
      phonenum
      username
      profileimg
      token
    }
  }
`;

export const getFollowingQuery = gql`
  query getFollowing {
    getFollowing {
      id
      username
      email
      profileimg
    }
  }
`;

export const isFollowingQuery = gql`
  query isFollowing($username: String!) {
    isFollowing(username: $username)
  }
`;

export const getUserInfoQuery = gql`
  query getUserInfo($username: String!) {
    getUserInfo(username: $username) {
      profileimg
      postCount
      followerCount
      followingCount
    }
  }
`;

export const followUserMutation = gql`
  mutation followUser($username: String!) {
    followUser(username: $username)
  }
`;

export const getProfileFeedQuery = gql`
  query getProfileFeed($username: String!) {
    getProfileFeed(username: $username) {
      id
      private
      heading
      body
      postMedia {
        fileName
        mediaType
      }
      createdAt
      user {
        username
        profileimg
      }
      comments {
        id
        user {
          username
        }
        body
        createdAt
      }
      likes {
        user {
          username
        }
        createdAt
      }
      likeCount
      commentCount
      selling
      sku
      price
    }
  }
`;

export const createOrderMutation = gql`
  mutation createOrder($postID: ID!, $extraInfo: [MetadataInput]!) {
    createOrder(postID: $postID, extraInfo: $extraInfo) {
      intentSecret
      orderID
    }
  }
`;

export const getSearchResultsQuery = gql`
  query search($query: String!, $limit: Int!) {
    search(query: $query, limit: $limit) {
      id
      name
      mediaType
      parents {
        order
      }
      rating
      metadata {
        name
        value
      }
      bookmarks {
        time
        note
      }
    }
  }
`;
