import React from "react";
import MomentsFeed from "./MomentsFeed";
import ProductsFeed from "./ProductsFeed";
import { Typography, styled } from "@mui/material";

import { TopSpacing } from "./styledComponents";

const MainContainer = styled("div")(({ theme }) => ({
  display: "grid",
  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "1fr",
    gridTemplateRows: "repeat(2, 1fr)",
  },
  [theme.breakpoints.up("sm")]: {
    gridTemplateColumns: "repeat(2, 50vw)",
    gridTemplateRows: "1fr",
  },
}));

const PrevMomentsVideos = styled("div")(() => ({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, 370px)",
  columnGap: "25px",
  rowGap: "25px",
  margin: "10px",
  justifyContent: "center",
}));

const LeftContent = styled("div")(() => ({
  display: "grid",
  gridTemplateColumns: "100%",
  gridTemplateRows: "100% min-content",
}));

const MainPicsCon = styled("div")(() => ({
  position: "relative",
  display: "grid",
  gridAutoRows: "auto",
  gridRowStart: 1,
  gridColumnStart: 1,
  overflow: "hidden",
}));

const RightContent = styled("div")(() => ({
  display: "grid",
  gridAutoRows: "min-content",
}));

const ContentContainer = styled("div")(() => ({
  padding: "20px",
}));

const BackgroundImage = styled("div")(() => ({
  width: "100%",
  height: "45vh",
  overflow: "hidden",
  position: "relative",
}));

const KidsImage = styled("img")(() => ({
  left: "-20%",
  objectFit: "cover",
}));

const InfoContainer = styled("div")(() => ({
  width: "90%",
  display: "grid",
  backgroundColor: "#0F0F0FF2",
  margin: "25px auto",
  gridTemplateRows: "repeat(3, min-content)",
  gridRowStart: 1,
  gridColumnStart: 1,
  zIndex: 1,
}));

const BlueHead = styled(Typography)(() => ({
  width: "300px",
  color: "#60c0c6",
  borderColor: "#60c0c6",
  borderBottom: "solid 8px",
  padding: "10px 5px",
}));

const FallenImage = styled("img")(() => ({
  objectFit: "cover",
}));

const RoseImage = styled("img")(() => ({
  top: "-40%",
  objectFit: "cover",
}));

const CasketImage = styled("img")(() => ({
  top: "-30%",
  objectFit: "cover",
}));

const TouchedImage = styled("img")(() => ({
  margin: "15px",
  width: "100%",
  objectPosition: "0px 10%",
  objectFit: "cover",
}));

const Main: React.FC = () => {
  return (
    <>
      <MainContainer>
        <LeftContent>
          <MainPicsCon>
            <BackgroundImage>
              <KidsImage
                src={`${process.env.REACT_APP_MMOMENTS_S3_URL}/kids_funeral.jpg`}
                alt="Kids at funeral"
              />
            </BackgroundImage>
            <BackgroundImage>
              <FallenImage
                src={`${process.env.REACT_APP_MMOMENTS_S3_URL}/fallen_soldier_funeral.jpg`}
                alt="Fallen solider funeral"
              />
            </BackgroundImage>
            <BackgroundImage>
              <CasketImage
                src={`${process.env.REACT_APP_MMOMENTS_S3_URL}/casket_drop.jpg`}
                alt="Casket drop"
              />
            </BackgroundImage>
            <BackgroundImage>
              <RoseImage
                src={`${process.env.REACT_APP_MMOMENTS_S3_URL}/rose_tombstone.jpg`}
                alt="Kids at funeral"
              />
            </BackgroundImage>
          </MainPicsCon>
          <InfoContainer>
            <ContentContainer>
              <BlueHead variant="h3">Mission</BlueHead>
              <TopSpacing>
                Losing a Loved one is always hard, but with our unique
                photography package(s) you’ll be able to view, save and share
                your “Memorable Moments” forever!
              </TopSpacing>
              <TopSpacing>
                *Some of our proceeds will be used to fund our youth photography
                program. Your contributions are much appreciated!
              </TopSpacing>
            </ContentContainer>
            <ContentContainer>
              <BlueHead variant="h3">Packages</BlueHead>
              <ProductsFeed limit={2} noLoad />
            </ContentContainer>
            <ContentContainer>
              <BlueHead variant="h3">Speaker</BlueHead>
              <div style={{ width: "90%", height: "45%", overflow: "hidden" }}>
                <TouchedImage
                  src={`${process.env.REACT_APP_MMOMENTS_S3_URL}/gotta_change_sitting_web.jpg`}
                  alt="Touched"
                />
              </div>
              <audio controls>
                <source
                  src={`${process.env.REACT_APP_MMOMENTS_S3_URL}/walk_by_faith.mp3`}
                  type="audio/mpeg"
                />
              </audio>
              <Typography style={{ paddingLeft: "20px" }}>
                "Touched" is a Inspirational Signer and Speaker who has
                performed numerous funerals, weddings and other momentous
                occasions. He's provided joy and happiness with closure and
                relief to many families. We look to accommodate your overall
                needs aswell, one moment at a time..
              </Typography>
            </ContentContainer>
          </InfoContainer>
        </LeftContent>
        <RightContent>
          <div>
            <BlueHead variant="h2">Past Moments</BlueHead>
          </div>
          <PrevMomentsVideos>
            <MomentsFeed />
          </PrevMomentsVideos>
        </RightContent>
      </MainContainer>
    </>
  );
};

export default Main;
